/**
 * Created by jonathano on 15-10-19.
 * Search Singleton
 */
var buzzSearchTimer = 0;
var buzzSearch = (function(){
    var staticPath = null,
        isToggled = false,
        searchBox = $('#search-box'),
        sbSearch = $('#sb-search'),
        mainHeader = $('#header .main'),
        mainNav = $('#main-nav'),
        results = $('#results'),
        closeIcon = $('#close-search'),
        contentOverlay = $('#content-overlay'),
        overlayCss = 'search-content-overlay',
        input = searchBox.find('input'),
        onFocus = 'search-focused',
        restUrl = '/v2/api/search-autocomplete/?highlight=1&q=', // highlight 1 - title will be highlighted
        rootUrl = '/search/?q=',
        maxDescLength = 90; // defaults to 90 characters

    // init default values for input and state.
    input.val('');
    input.prop('disabled', true);

    /**
     * searchByTerm
     * @param term
     */
    function searchByTerm(term) {
        var allResults = 0,
            RESULT_OFFSET = 8;

        $.getJSON(restUrl + term, function(data) {
            if (!data || data.count == 0) { results.hide(); tooglecontentOverlay(true); return; }
            // otherwise
            clearSearchResult(); // if any
            var lengthBefore = data.count;
            if (lengthBefore) { tooglecontentOverlay(true); }
            else { tooglecontentOverlay(false); }
            var searchResults = data.results.splice(0, RESULT_OFFSET);
            allResults = lengthBefore - searchResults.length;
            searchResults.forEach(function(item){
                buildResultItem(item)
            });
            if (allResults) {
                var li = $('<li class="search-more"></li>');
                var wrapper = $('<div class="item"><div>');
                wrapper.html(
                    '<a href="' + rootUrl + term + '">' +
                        '<i class="ic-search"></i>' +
                        '<p>' + 
                        '<span class="title">' + 'Show all results...' + '</span>' +
                        '<span class="description">' + allResults + ' more results' + '</span>' +
                        '</p>' +
                    '</a>'
                );
                results.append(li);
                li.append(wrapper);
            }
            results.show();
        });
    }

    /**
     * clear results
     */
    function clearSearchResult() {
        results.hide();
        if ( results && results.children().length) {
            results.empty();
        }
        if (contentOverlay.hasClass(overlayCss)) {
            tooglecontentOverlay(false);
        }
    }

    function hideSmartbanner() {
        if($('#smartbanner').length > 0) { $('#smartbanner').slideUp(); }
    }

    function tooglecontentOverlay(activate) {
        if(activate) {
            hideSmartbanner();
            contentOverlay.addClass(overlayCss);
            $('body').addClass('search-expanded');
        } else {
            contentOverlay.removeClass(overlayCss);
            $('body').removeClass('search-expanded');
        }
    }

    /**
     * click events
     */
    function registerHelpers() {
        sbSearch.click(function(e){
            var target = e.target;
            $("#clicktarget").hide();
            input.prop('disabled', false);
            tooglecontentOverlay(true);
            closeIcon.fadeIn(300);
            searchBox.addClass('b-search-triggered').bind('transitionend', function () {
                    input.focus();
                    input.addClass(onFocus);                
                });
        });

        // attach event to the overlay and close button
        contentOverlay.add(closeIcon).add(mainHeader).click(function() {
                input.prop('disabled', true);
                closeIcon.hide();
                $("#clicktarget").show();
                input.val('');
                searchBox.removeClass('b-search-triggered').bind('transitionend', function () {
                    input.removeClass(onFocus);             
                });             
                clearSearchResult();            
        });

        $('#search-icon').click(function(e) {
            var val = $('#search-icon').parent().find('input').val();
            if(val) {
                window.location.href = rootUrl + val;
                return;
            }
        });
    }

    /**
     * ui results
     * @param item
     */
    function buildResultItem(item) {
        if (typeof item !== 'object') { throw Error('not a valid result item', 'buzzsearch.js') }

        // dynamically build required html
        var li = $('<li></li>'), wrapper = $('<div class="item"></div>');
        var img = new Image();
        var defaultImg = staticPath + 'images/logo-sq-sml.png';
        img.className = 'search-thumb';

        // validate image and append to result
        var target = false;
        if(item.featured_image) {
            target = item.thumbnails['60x60'];
        }
        
        img.src =  target ? target : defaultImg;
        
        $(img).error(function(){
            $(this).attr('src', defaultImg);
        });
        //wrapper.append(img);

        // create anchor with slug and append to wrapper
        var anchor = $('<a></a>'), text = $('<p></p>'), title = $('<span class="title"></span>'), description = $('<span class="description"></span>');        
        anchor.attr('href','/' + item.slug);        
        wrapper.append(anchor);
        anchor.append(img);
        anchor.append(text);
        text.append(title, description);
        title.html(truncate(item.title, maxDescLength));
        description.html(truncate(item.description, maxDescLength));    
        // append list item and its contents
        results.append(li);
        li.append(wrapper);
    }

    function truncate(str, maxLength) {
        return jQuery.truncate(str, {
          length: maxLength,
          words: true
        });
    }

    /**
     * Cherry picks from a available thumbnails
     * @param src
     * @param width
     * @param height
     * @returns {*}
     */
    function resize(src, width, height) {
        var extension = '.jpg'; 
        var target = src && src.length;

        //Not sure where the media uploads are going to be stored, so we do a check
        if (src.indexOf('blog/') > -1){
            target = src && src.length ? src.replace('uploads/blog/', 'uploads/blog/.thumbnails/') : null;
            //console.log("It Does");           
        } else {
            target = src && src.length ? src.replace('uploads/', 'uploads/.thumbnails/') : null;
        }
        //console.log(src);
        //console.log(target);       

        return target ? target + '/' + src.substring(src.lastIndexOf('/') + 1, src.length - extension.length) + '-' + width + 'x' + height + extension : null;
    }

    // public API
    return {
        MAX_DESC_LENGTH: 90,
        MIN_DESC_LENGTH: 50,
        /**
         * Initializes static paths to fetch thumbnails
         * @param path
         */
        init: function(path) {
            if (path !== null) {
                staticPath = path;
            }
            registerHelpers();
        },

        /**
         * fetchData from target
         * Grab data from input and render results
         * @param target for input or defaults to input #search-box
         * @param whether it should update UI or not with results
         */
        fetchData: function(target) {
            var targetInput = target;
            if (!targetInput) {
                targetInput = input;
                results.appendTo('#search-box');
            } else {
                results.prependTo('.contain-to-grid');
            }
            targetInput.keyup(function(e){
                if (e.which === 13) {
                    window.location.href = rootUrl + $(this).val();
                    return;
                }

                // cancel any previously-set timer
                if (buzzSearchTimer) {
                    clearTimeout(buzzSearchTimer);
                }

                var searchInputVal = $(this).val();

                // otherwise
                buzzSearchTimer = setTimeout(function() {
                    searchByTerm(searchInputVal);
                }, 200);
            });
        },

        /**
         * public api to clear results
         */
        clearResults: function() { clearSearchResult(); },
        hideSmartbanner: function() { hideSmartbanner(); },

        tooglecontentOverlay: function(activate) { tooglecontentOverlay(activate); },

        setDescLenght: function(length) {
            if (length) { maxDescLength = length; }

        }
    }
}());
